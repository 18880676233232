/* eslint-disable import/prefer-default-export */
import React from 'react';
export function getColumnKey(column, defaultKey) {
    if ('key' in column && column.key !== undefined && column.key !== null) {
        return column.key;
    }
    if (column.dataIndex) {
        return (Array.isArray(column.dataIndex) ? column.dataIndex.join('.') : column.dataIndex);
    }
    return defaultKey;
}
export function getColumnPos(index, pos) {
    return pos ? `${pos}-${index}` : `${index}`;
}
/**
 * Get first text content in Element
 *
 * @param node
 * @returns
 */
function getElementFirstTextContent(node) {
    var _a, _b, _c;
    if (!node || !node.props || !node.props.children)
        return '';
    if (typeof node.props.children === 'string')
        return node.props.children;
    return (((_c = (_b = (_a = node.props.children) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, (item) => getElementFirstTextContent(item))) === null || _c === void 0 ? void 0 : _c[0]) || '');
}
export function renderColumnTitle(title, props) {
    if (typeof title === 'function') {
        return title(props);
    }
    // fix: #38155
    if (React.isValidElement(title)) {
        // if title is a React Element, we should get first text content as result,
        // if there has not text content in React Element, return origin title
        return getElementFirstTextContent(title) || title;
    }
    return title;
}
